import "./styles.css";
import { createRoot } from "react-dom/client";
import {
  Form,
  RouterProvider,
  createMemoryRouter,
  redirect,
  useLoaderData,
  useNavigation,
  useRevalidator,
  type RouteObject,
} from "react-router-dom";
import usePartySocket from "partysocket/react";
import PartySocket from "partysocket";
import { useEffect, useRef } from "react";

const routes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    loader: async () => {
      const url = new URL(window.location.toString());
      const token = url.searchParams.get("token");
      return { token };
    },
    action: async ({ request }) => {
      const params = await request.formData();
      const name = params.get("name");
      const email = params.get("email");
      const message = params.get("message");
      const embed = params.get("embed") as string;

      if (!name || !email || !embed) {
        return redirect(`/?embed=${embed}`);
      }

      // TODO: Pull the token out of localstorage and send that
      // to the server for persistent sessions
      const response = await PartySocket.fetch(
        {
          host: window.location.host,
          party: "main",
          room: "main",
          path: "chat",
        },
        {
          method: "POST",
          body: JSON.stringify({ name, email, message, embed }),
        }
      );
      try {
        const data = await response.json();

        const token = data.token;
        if (!token) throw new Error("No token returned from server");

        return redirect(`/chat?token=${token}`);
      } catch (error) {
        console.error(error);
        return redirect("/");
      }
    },
  },
  {
    path: "/chat",
    element: <Chat />,
    loader: async ({ request }) => {
      const url = new URL(request.url);
      const params = new URLSearchParams(url.search);
      const token = params.get("token");

      if (!token) {
        return redirect("/");
      }

      const messages = await PartySocket.fetch({
        host: window.location.host,
        party: "chat",
        room: token,
        path: "messages",
      });

      return { token, messages: await messages.json() };
    },
    action: async ({ request }) => {
      const params = await request.formData();
      const message = params.get("message");
      const token = params.get("token") as string;
      if (!token) {
        return redirect("/");
      }
      if (!message) {
        return redirect(`/chat?token=${token}`);
      }

      await PartySocket.fetch(
        {
          host: window.location.host,
          party: "chat",
          room: token,
          path: "message",
        },
        {
          method: "POST",
          body: JSON.stringify({ message }),
        }
      );

      return { ok: true };
    },
  },
];

const router = createMemoryRouter(routes, {
  initialEntries: ["/"],
  initialIndex: 0,
});

function App() {
  const navigation = useNavigation();
  const { token } = useLoaderData() as {
    token: string | null;
  };
  return (
    <main>
      {token ? (
        <Form
          method="POST"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <label>
            Name:
            <input defaultValue="Alex" name="name" type="text" />
          </label>
          <label>
            Email:
            <input
              defaultValue="hey@ralexanderson.com"
              name="email"
              type="email"
            />
          </label>
          <input type="hidden" name="token" value={token} />
          <label>
            Message:
            <input name="message" defaultValue="This is a test message" />
          </label>
          <button type="submit" disabled={navigation.state !== "idle"}>
            {navigation.state !== "idle" ? "Loading..." : "Submit"}
          </button>
        </Form>
      ) : (
        <h1>
          Your chat embed is not initialized correctly. Token is not present in
          the embed URL. Visit{" "}
          <a href={`${window.location.origin}/parties/main/main/install`}>
            {window.location.origin}/parties/main/main/install
          </a>{" "}
          to install the app and then use <code>/embed</code> to get an embed
          code for the channel.
        </h1>
      )}
    </main>
  );
}

function Chat() {
  const loaderData = useLoaderData() as {
    token: string;
    messages: { m: string; n: string; t: number }[];
  };

  const navigation = useNavigation();
  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (navigation.state === "idle" && inputRef.current) {
      inputRef.current.value = "";
      inputRef.current.focus();
    }
  }, [navigation.state]);

  const { revalidate } = useRevalidator();

  usePartySocket({
    party: "chat",
    room: loaderData.token,
    onMessage() {
      revalidate();
    },
  });

  const messages = [
    loaderData.messages,
    navigation.state !== "idle"
      ? {
          m: navigation.formData?.get("message"),
          t: Date.now(),
          n: "Me",
        }
      : [],
  ]
    .flat()
    .reverse();

  return (
    <main>
      <h1>Chat</h1>
      <Form method="POST" style={{ display: "flex" }}>
        <label>
          Message:
          <input name="message" ref={inputRef} />
        </label>
        <input name="token" type="hidden" value={loaderData.token} />
        <button type="submit" disabled={navigation.state !== "idle"}>
          Send
        </button>
      </Form>
      <pre>{JSON.stringify(messages, null, 2)}</pre>
    </main>
  );
}

// biome-ignore lint/style/noNonNullAssertion: <explanation>
createRoot(document.getElementById("app")!).render(
  <RouterProvider router={router} />
);
